<template>
  <div>
    <h3>INFORMACIÓN A USUARIOS FINANCIEROS</h3>
    <h4>Tasas, comisiones y cargos</h4>
    <div style="overflow-x: auto">
      <table>
        <tr>
          <th>Producto</th>
          <th>Plazo</th>
          <th></th>
          <th>Tasa Nominal<br>Anual (TNA)</th>
          <th>Tasa Efectiva<br>Anual (TEA)</th>
          <th>CFTEA con<br>IVA</th>
          <th>Comisiones y gastos<br>de otorgamiento</th>
          <th>Comisión por precancelación<br>total anticipada</th>
          <th>Seguros</th>
        </tr>

        <tr>
          <td rowspan="2">
            App Prester
          </td>
          <td rowspan="2">
            {{ minPeriodAppPrester }} a {{ maxPeriodAppPrester }} meses
          </td>
          <td>
            Mínima
          </td>
          <td>
            {{ minTNAAppPrester }}%
          </td>
          <td>
            {{ minTEAAppPrester }}%
          </td>
          <td>
            {{ minCFTEAWithIvaAppPrester }}%
          </td>
          <td>
            {{ minCommissionsAndGrantingExpensesAppPrester }}%
          </td>
          <td>
            {{ minTotalEarlyPrepaymentFeeAppPrester }}%
          </td>
          <td>
            {{ minInsuranceAppPrester }}%
          </td>
        </tr>

        <tr>
          <td>
            Máxima
          </td>
          <td>
            {{ maxTNAAppPrester }}%
          </td>
          <td>
            {{ maxTEAAppPrester }}%
          </td>
          <td>
            {{ maxCFTEAWithIvaAppPrester }}%
          </td>
          <td>
            {{ maxCommissionsAndGrantingExpensesAppPrester }}%
          </td>
          <td>
            {{ maxTotalEarlyPrepaymentFeeAppPrester }}%
          </td>
          <td>
            {{ maxInsuranceAppPrester }}%
          </td>
        </tr>

        <tr>
          <td rowspan="2">
            Comercializadoras
          </td>
          <td rowspan="2">
            {{ minPeriodComercializadoras }} a {{ maxPeriodComercializadoras }} meses
          </td>
          <td>
            Mínima
          </td>
          <td>
            {{ minTNAComercializadoras }}%
          </td>
          <td>
            {{ minTEAComercializadoras }}%
          </td>
          <td>
            {{ minCFTEAWithIvaComercializadoras }}%
          </td>
          <td>
            {{ minCommissionsAndGrantingExpensesComercializadoras }}%
          </td>
          <td>
            {{ minTotalEarlyPrepaymentFeeComercializadoras }}%
          </td>
          <td>
            {{ minInsuranceComercializadoras }}%
          </td>
        </tr>

        <tr>
          <td>
            Máxima
          </td>
          <td>
            {{ maxTNAComercializadoras }}%
          </td>
          <td>
            {{ maxTEAComercializadoras }}%
          </td>
          <td>
            {{ maxCFTEAWithIvaComercializadoras }}%
          </td>
          <td>
            {{ maxCommissionsAndGrantingExpensesComercializadoras }}%
          </td>
          <td>
            {{ maxTotalEarlyPrepaymentFeeComercializadoras }}%
          </td>
          <td>
            {{ maxInsuranceComercializadoras }}%
          </td>
        </tr>
      </table>
    </div>
    <h5>Desliza hacia la derecha para ver la tabla completa.</h5>
  </div>
</template>

<script>
export default {
  name: "PersonalAllons",
  data: function () {
    return {
        minPeriodAppPrester: process.env.VUE_APP_minPeriodAppPrester,
        maxPeriodAppPrester: process.env.VUE_APP_maxPeriodAppPrester,
        minTNAAppPrester: process.env.VUE_APP_minTNAAppPrester,
        minTEAAppPrester: process.env.VUE_APP_minTEAAppPrester,
        minCFTEAWithIvaAppPrester: process.env.VUE_APP_minCFTEAWithIvaAppPrester,
        minCommissionsAndGrantingExpensesAppPrester: process.env.VUE_APP_minCommissionsAndGrantingExpensesAppPrester,
        minTotalEarlyPrepaymentFeeAppPrester: process.env.VUE_APP_minTotalEarlyPrepaymentFeeAppPrester,
        minInsuranceAppPrester: process.env.VUE_APP_minInsuranceAppPrester,
        maxTNAAppPrester: process.env.VUE_APP_maxTNAAppPrester,
        maxTEAAppPrester: process.env.VUE_APP_maxTEAAppPrester,
        maxCFTEAWithIvaAppPrester: process.env.VUE_APP_maxCFTEAWithIvaAppPrester,
        maxCommissionsAndGrantingExpensesAppPrester: process.env.VUE_APP_maxCommissionsAndGrantingExpensesAppPrester,
        maxTotalEarlyPrepaymentFeeAppPrester: process.env.VUE_APP_maxTotalEarlyPrepaymentFeeAppPrester,
        maxInsuranceAppPrester: process.env.VUE_APP_maxInsuranceAppPrester,
        minPeriodComercializadoras: process.env.VUE_APP_minPeriodComercializadoras,
        maxPeriodComercializadoras: process.env.VUE_APP_maxPeriodComercializadoras,
        minTNAComercializadoras: process.env.VUE_APP_minTNAComercializadoras,
        minTEAComercializadoras: process.env.VUE_APP_minTEAComercializadoras,
        minCFTEAWithIvaComercializadoras: process.env.VUE_APP_minCFTEAWithIvaComercializadoras,
        minCommissionsAndGrantingExpensesComercializadoras: process.env.VUE_APP_minCommissionsAndGrantingExpensesComercializadoras,
        minTotalEarlyPrepaymentFeeComercializadoras: process.env.VUE_APP_minTotalEarlyPrepaymentFeeComercializadoras,
        minInsuranceComercializadoras: process.env.VUE_APP_minInsuranceComercializadoras,
        maxTNAComercializadoras: process.env.VUE_APP_maxTNAComercializadoras,
        maxTEAComercializadoras: process.env.VUE_APP_maxTEAComercializadoras,
        maxCFTEAWithIvaComercializadoras: process.env.VUE_APP_maxCFTEAWithIvaComercializadoras,
        maxCommissionsAndGrantingExpensesComercializadoras: process.env.VUE_APP_maxCommissionsAndGrantingExpensesComercializadoras,
        maxTotalEarlyPrepaymentFeeComercializadoras: process.env.VUE_APP_maxTotalEarlyPrepaymentFeeComercializadoras,
        maxInsuranceComercializadoras: process.env.VUE_APP_maxInsuranceComercializadoras
    }
  },
};
</script>

<style scoped>
  table {
    width: 100%;
  }
  th {
    text-align: center;
    padding: 0px 10px;
  }
  td {
    text-align: center;
    padding: 0px 10px;
  }
</style>
